.contact {
  // background: no-repeat center
  //   url("../../../../assets/images/icons/bg-send.png");
  background: rgba(250, 205, 73, 0.08);
  text-align: center;
  border-radius: 30px;
  padding-bottom: 50px;
  padding-top: 50px;
  margin: 0 60px;
  box-shadow: 14px 16px 30px rgba(0, 0, 0, 0.1);
  z-index: 1;
  .slogan {
    width: 70%;
    font-size: 24px;
    font-weight: 500;
    margin: 40px auto;
  }
  .form-contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .info-user {
      width: 70%;
      display: flex;
      margin-bottom: 16px;
      .input-name,
      .input-phone {
        filter: drop-shadow(4px 8px 10px rgba(0, 0, 0, 0.12));
        width: 100%;
        padding: 20px;
        border-radius: 10px;
        border: inherit;
      }
      .input-item
      .input-name {
        margin-right: 16px;
      }
    }
    .input-email,
    .input-detail {
      filter: drop-shadow(4px 8px 10px rgba(0, 0, 0, 0.12));
      width: 70%;
      padding: 20px;
      border-radius: 10px;
      border: inherit;
      margin-bottom: 16px;
    }
    .send-btn {
      padding: 20px 50px;
      background: var(--black-color);
      color: var(--white-color);
      border: inherit;
      border-radius: 10px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      margin-left: 12px;
      &:hover {
        background: #393939;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .contact {
    margin: 150px 20px 0;
    .slogan {
      width: 90%;
    }
    .form-contact {
      .info-user,
      .input-email,
      .input-detail {
        width: 90%;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .contact {
    margin: 150px 5px 0;
    .slogan {
      width: 100%;
    }
    .form-contact {
      padding: 0 10px;
      .info-user {
        flex-direction: column;
        .input-name {
          margin-bottom: 16px;
        }
      }
      .info-user,
      .input-email,
      .input-detail {
        width: 100%;
      }
    }
  }
}
