.nav-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fcfdfd;
}
.navbar {
  max-width: 1440px;
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  z-index: 100;
  position: relative;
  .logo-img {
    width: 200px;
    object-fit: fill;
    cursor: pointer;
    transition: all 0.4s ease;
    &.scale {
      width: 150px;
    }
  }
  .main-right {
    display: flex;
    .nav-content {
      display: flex;
      align-items: center;
      list-style: none;
      .nav-item {
        padding: 8px 30px;
        margin-right: 15px;
        font-size: 16px;
        font-weight: 600;
        color: var(--gray7-color);
        cursor: pointer;
        &.hide {
          display: none;
        }
        &:hover,
        &.select {
          color: var(--primary-color);
          text-decoration: underline;
        }
      }
    }
    .contact-btn {
      padding: 8px 24px;
      font-size: 16px;
      font-weight: 600;
      color: var(--white-color);
      background: var(--primary-color);
      box-shadow: 0px 13px 28px rgba(0, 0, 0, 0.2);
      border: none;
      border-radius: 8px;
      cursor: pointer;
      &.hide {
        display: none;
      }
      &:hover {
        background: var(--primary-hover);
      }
    }
  }
}

.navbar-mobile {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .navbar {
    padding: 0 10px;
    .main-right .nav-content .nav-item {
      padding: 0 10px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .navbar {
    display: none;
  }
  .navbar-mobile {
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcfdfd;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    min-height: 64px;
    padding: 0 10px;
    .logo-img {
      width: 200px;
      object-fit: fill;
      cursor: pointer;
      transition: all 0.4s ease;
      &.scale {
        width: 150px;
      }
    }
    .bg-overplay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
    }
    .main-right {
      position: relative;
      .btn-hamburger {
        border: none;
        background: none;
        cursor: pointer;
      }
      .navbar-child {
        background: var(--white-color);
        box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        padding: 16px;
        position: absolute;
        right: 50%;
        transition: 0.25s ease-in-out;
        pointer-events: none;
        opacity: 0;
        .nav-content {
          list-style-type: none;
          .nav-item {
            font-size: 16px;
            font-weight: 500;
            color: var(--gray7-color);
            cursor: pointer;
            margin-bottom: 16px;
            text-align: center;
            &.hide {
              display: none;
            }
            &.select {
              color: var(--primary-color);
              text-decoration: underline;
            }
            &.rmb {
              margin-bottom: 0;
            }
          }

          .nav-btn {
            padding: 8px;
            font-size: 16px;
            font-weight: 600;
            color: var(--white-color);
            background: var(--primary-color);
            border: none;
            border-radius: 8px;
            cursor: pointer;
            &.hide {
              display: none;
            }
            &:hover {
              background: var(--primary-hover);
            }
          }
        }
        &.show {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }
}
