* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#root_page {
  position: relative;
  overflow-x: auto;
  min-height: 100vh;
  .Toastify__toast-theme--light {
    border-radius: 12px;
    box-shadow: 0 1px 10px 0 rgb(239 78 174 / 44%),
      0 2px 15px 0 rgb(239 78 174 / 14%);
    border: 1px solid var(--pink2-color);
  }
  .Toastify__toast-body {
    padding: 0;
  }
}

input[type="number"]::-webkit-outer-spin-button,
[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.landing-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.landing-container .landing-body {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.img-loading {
  position: fixed;
  top: 10%;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: var(--pink4-color);
  &.hide {
    transition: opacity 0.3s ease-in;
    opacity: 0;
    // z-index: -1;
    pointer-events: none;
  }
}

.img-loading .icon-loading {
  position: absolute;
  top: 30%;
  left: 30%;
  max-width: 40%;
  object-fit: cover;
  opacity: 1;
}
